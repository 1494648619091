<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-input
              class="input"
              v-model="queryParams.postId"
              placeholder="帖子ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in typeList" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in postStatusList" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.content"
              placeholder="内容"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="typeStatus" slot-scope="text, record">
        <!-- <div>{{ record.type }}</div> -->
        <a-tag :color="record.type | dictName('feedbackTypeStatus', 'color')">{{
            record.type | dictName("feedbackTypeStatus")
          }}
        </a-tag>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :default-checked="record.isTop==1" @change="changeTopState(record)" :loading="showLoading"/>
      </template>
      <template slot="countInfo" slot-scope="text, record">
        <div class="count-info" v-if="record.type==1">
          <span>发帖方 {{ record.positiveCount }}</span><span>反对方 {{ record.negativeCount }}</span></div>
      </template>
      <template slot="images" slot-scope="text, record">
        <div v-if="record.imageList.length > 0" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="record.imageList[0] | fullImgPath"
              fit="contain"
              :preview-src-list="record.imageList || []"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="postId" slot-scope="text, record">
        <a href="javascript:void(0)" @click="openPostModal(record)"
           style="text-decoration: underline;color:rgb(24, 144, 255)">
          {{ text }}
        </a>
      </template>
      <template slot="status" slot-scope="text, record">
        <div title="点击审核" @click.stop="auditPost(record)" style="cursor: pointer">
          <a-tag :color="record.status | dictName('postStatus', 'color')" style="cursor: pointer">
            <span v-if="record.status==0">去审核</span>
            <span v-else>{{ record.status| dictName("postStatus") }}</span>
          </a-tag>
        </div>
      </template>
      <template slot="content" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> {{ record.content }}</template>
          <div>
            {{
              record.content.length > 30
                  ? record.content.slice(0, 30) + "..."
                  : record.content
            }}
          </div>
          <div v-if="record.imageList&&record.imageList.length > 0" class="img-list">
            <el-image
                style="width: 40px; height: 40px"
                v-for="oneImg in record.imageList"
                :key="oneImg"
                :src="oneImg| fullImgPath"
                fit="contain"
                :preview-src-list="record.imageList || []"
            />
          </div>
        </a-tooltip>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="danger" size="small" @click="deleteOne(record)">删除</a-button>
      </template>
    </a-table>
    <feedback-audit-modal ref="feedbackAuditModal" @success="editSuccess"></feedback-audit-modal>
    <modify-post-modal ref="modifyPostModal"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import FeedbackAuditModal from "./feedbackAuditModal.vue";
import ModifyPostModal from "../postlist/modifyModal.vue";
import {DICT} from "@/utils/dict";

export default {
  name: "FeedbackList",
  mixins: [ListMixin],
  components: {FeedbackAuditModal, ModifyPostModal},
  data() {
    return {
      listGetUrl: "feedback/list",
      listMethod: "get",
      showLoading: false
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    postStatusList() {
      return DICT['postStatus']
    },
    typeList() {
      return DICT['feedbackTypeStatus']
    },
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "用户名",
          align: "center",
          width: 150,
          dataIndex: "username",
          //   scopedSlots: { customRender: "amount" },
        },
        {
          title: "类型",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "typeStatus"},
        },
        {
          title: "原帖子ID",
          width: 80,
          align: "center",
          dataIndex: "postId",
          scopedSlots: {customRender: "postId"},
        },
        {
          title: "评论ID",
          width: 80,
          align: "center",
          dataIndex: "commentId",
        },
        // {
        //   title: "图片凭证",
        //   width: 100,
        //   dataIndex: "imageList",
        //   align: "center",
        //   scopedSlots: {customRender: "images"},
        // },
        {
          title: "争议/举报内容",
          width: 150,
          align: "center",
          dataIndex: "content",
          scopedSlots: {customRender: "content"},
        },
        {
          title: "状态",
          width: 100,
          align: "center",
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "置顶|推荐",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "isTop"},
        },
        {
          title: "审核总结",
          align: "center",
          width: 150,
          dataIndex: "summary",
          ellipsis: true,
        },
        {
          title: "发帖方描述",
          align: "center",
          width: 150,
          dataIndex: "positiveDesc",
          ellipsis: true,
        },
        // {
        //   title: "争议描述",
        //   align: "center",
        //   width: 150,
        //   dataIndex: "negativeDesc",
        //   ellipsis: true,
        // },
        {
          title: "支持数量",
          align: "center",
          width: 150,
          scopedSlots: {customRender: "countInfo"},
        },
        {
          title: "联系方式",
          align: "center",
          width: 150,
          dataIndex: "contact",
          //   scopedSlots: { customRender: "amount" },
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    openPostModal(record) {
      this.$refs.modifyPostModal.showModal(record.postId, 'view')
    },
    auditPost(record) {
      if (this.$helper.hasPermission('post:audit')) {
        this.$refs.feedbackAuditModal.showModal(record);
      } else {
        this.$message.warn("对不起，您没有审核权限")
      }
    },
    afterFetch(data) {
      data.records.forEach(v => {
        try {
          v.imageList = JSON.parse(v.images)
        } catch (e) {
          v.imageList = []
        }
      })
    },

    changeTopState(record) {
      let that = this;
      let data = {
        id: record.id,
        isTop: record.isTop == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("feedback/isTop", data).then(() => {
        that.showLoading = false;
        let mes =
            record.postTop != 1
                ? "ID：" + record.id + "置顶成功"
                : "ID：" + record.id + "取消置顶成功";
        that.$message.success(mes + "请前往小程序查看");
        this.search();
      });
    },
    deleteOne(record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【ID：" + record.id + "】的争议吗？！",
        centered: true,
        onOk() {
          that.$delete("feedback/delete/" + record.id).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}

.content {
  max-height: 100px;
  overflow-y: auto;
}

.count-info {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #FFF;
    font-size: 12px;
    text-align: center;
    padding: 2px 5px;

    &:first-child {
      background: red;
    }

    &:last-child {
      background: #5794F8;
    }
  }

}
</style>
